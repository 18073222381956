import React from 'react'
import { Link } from 'gatsby'
import { useTheme } from '../Theming'
import ThemeToggler from './ThemeToggler'
import { FaHome } from 'react-icons/fa'
import { css} from '@emotion/core'
import {useSpring,animated as a, config} from 'react-spring'
export default () => {
  const theme = useTheme()
  
  const animation = useSpring({
    from: {height:'10vh',opacity:0},
    to: {height:'100vh',opacity:1},
    config: {
      ...config.gentle,
      clamp: true
    }
   
  })
  return (
   
      <a.div style={animation} css={css`
      display:flex;
       flex-direction:column;
      background:black;
       position:fixed;
      justify-content:space-between;
       bottom:0;
      padding:10px  0;
      left:0;
      right:0;
       width:100vw;
      
      
       
      a:hover {
        color:#00ff33;
      }
      
    
       a {
        color:${theme.colors.white};
        text-align:center;
        padding:10px 0;
        font-size:1.1rem;
       
      }
      
      justify-content:center;
      margin:0;
      ul {background:black;
       
      }
      @media(max-width:568px and (orientation:landscape)){
        ul{
          height:80vh;
          font-size:.8rem;
          
        }
        a {
          padding:5px;
          margin:0;
          font-size:1.1rem;
        }
      }
      @media(max-width:800px) and (orientation:landscape){
        padding-top:-40px;
        a {
          padding:4px;
          margin:0;
          font-size:1rem;
         
          letter-spacing:0.08rem;
        }
      }
        
      }
      @media(min-width:801px){
        a {
          padding:18px;
          margin:0;
          font-size:1.4rem;
        }
      }
      
       
      `}>
       <ul css={css`
        padding:30px 0;
        display:flex;
        flex-direction:column;
        justify-content: space-between;
        list-style:none;
        text-align:center;
        overflow:scroll;
       
        
       `}>
     lepidoptera
      <li><Link
    
      to="/" activeStyle={{color:`lightgrey`,enabled:false,cursor: `none`}} aria-label="Zur Homeseite">
       <FaHome />
      </Link>
       </li>
       <li><Link activeStyle={{color:`lightgrey`,enabled:false,cursor: `none`}} to="/moga-zirden" activeClassName="active" aria-label="Mireille Mora &amp; Sylvia Zirden">
       Mireille Mora &amp; Sylvia Zirden | Panta Rhei(n) 2
      </Link> </li>
       <li><Link activeStyle={{color:`lightgrey`,enabled:false,cursor: `none`}} to="/mareike-knevels" activeClassName="active" aria-label="Mareike Knevels">
       Mareike Knevels | Panta Rhei(n) 1
      </Link> </li>
      <li><Link activeStyle={{color:`lightgrey`,enabled:false,cursor: `none`}} to="/knevelsillustrationen" activeClassName="active" aria-label="Mareike Knevels">
       Mareike Knevels | Illustrationen
      </Link> </li>
       <li><Link activeStyle={{color:`lightgrey`,enabled:false,cursor: `none`}} to="/isabel-antonio" activeClassName="active" aria-label="Isabel Antonio">
       Isabel Antonio | Der kleine Garten vor meinem Fenster
      </Link> </li>
      <li><Link activeStyle={{color:`lightgrey`,enabled:false,cursor: `none`}} to="/verluste" activeClassName="active" aria-label="Verluste">
        Menschen, Stimmungen, Erinnerungen
      </Link> </li>
      <li><Link activeStyle={{color:`lightgrey`,enabled:false,cursor: `none`}} to="/lepidoptera" activeClassName="active" aria-label="Benjamin May">
        Schmetterlinge | Benjamin May
      </Link> </li>
       <li><Link activeStyle={{color:`lightgrey`,enabled:false,cursor: `none`}} to="/jkreiensiek" activeClassName="active" aria-label="Joachim Kreiensiek">
        Im Fluss der Jahreszeiten | Heinrich Lee alias Joachim Kreiensiek
      </Link> </li>
       <li><Link activeStyle={{color:`lightgrey`,enabled:false,cursor: `none`}} to="/juedischer-alltag" activeClassName="active" aria-label="Juedischer Alltag">
        Jüdischer Alltag in Deutschland | Fotowettbewerb
      </Link> </li>
       <li><Link activeStyle={{color:`lightgrey`,enabled:false,cursor: `none`}} to="/evelyn-sattler" activeClassName="active" aria-label="Evelyn Sattler">
       Die Malerin Evelyn Sattler
      </Link> </li>
       <li><Link activeStyle={{color:`lightgrey`,enabled:false,cursor: `none`}} to="/fotos-weltenfrauen" activeClassName="active" aria-label="Weltenfrauen">
       Weltenfrauen
      </Link> </li>
       <li><Link activeStyle={{color:`lightgrey`,enabled:false,cursor: `none`}} to="/camera_obscura" activeClassName="active" aria-label="Camera Obscura">
       Camera Obscura
      </Link> </li>
       <li><Link  activeStyle={{color:`lightgrey`,enabled:false,cursor: `none`}} to="/buga2029" activeClassName="active" aria-label="Buga2029">
      Buga 2029 / Minimalbehausung
      </Link> </li>
      <li><Link activeStyle={{color:`lightgrey`,enabled:false,cursor: `none`}} to="/urbansketcher" activeClassName="active" aria-label="Urban Sketcher">
      Urban Sketch – Voyage 2.0
      </Link> 
      </li>
      <li>
        
        <Link 
        activeStyle={{color:`lightgrey`,enabled:false,cursor: `none`}}
       to="/bacchanale"  aria-label="Ausstellungen">
        Bacchanale 2019
      </Link>
      </li>
      <li><Link activeStyle={{color:`lightgrey`,enabled:false,cursor: `none`}} to="/markt1filme" activeClassName="active" aria-label="Videogalerie">
        Videogalerie
      </Link>
      </li>
      <li><Link  activeStyle={{color:`lightgrey`,enabled:false,cursor: `none`}} to="/ueber_uns" activeClassName="active" aria-label="Über uns">
        Über uns
      </Link> </li>
       {/* <li><Link  activeStyle={{color:`lightgrey`,enabled:false,cursor: `none`}} to="/kulturtipps" activeClassName="active" aria-label="Videogalerie">
       Kulturtipps
      </Link>
      </li> */}
     
{/*      
      <li><Link activeStyle={{color:`grey`,enabled:false,cursor: `none`}} to="/foerderer" activeClassName="active" aria-label="Förderer">
    Förderer
      </Link> </li>
       */}
      <li><Link activeStyle={{color:`grey`,enabled:false,cursor: `none`}} to="/kontakt" activeClassName="active" aria-label="Förderer">

    Kontakt &amp; Datenschutz
      </Link> </li>

      <li><a href="https://kulturnetz-oberes-mittelrheintal.org" target="_blank" rel="noreferrer noopener" aria-label="Kulturnetz ">Kulturnetz K.O.M</a>
        
       </li>
     <li>
      <ThemeToggler
        css={{}}
        toggleTheme={theme.toggleTheme}
        themeName={theme.themeName}
      />
      </li>
   </ul>
     

      </a.div>
     
    
  )
}
