import styled from '@emotion/styled'

export default styled.article`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width:48%;
  
  font-style:normal;
  
  padding:0;
  margin:30px 0;
  
  
  h2 {
    margin:10px 0;
    color: #3333FF !important;
    font-weight:300;
  }
  
  :nth-of-type(even) {
    padding:0;
    font-style:italic;
    h2 {
      color: #3333FF !important;
      font-weight:300;
      margin:10px 0;
    }
  }
  :nth-of-type(odd) {
    
   
  }
  
  }
  @media(max-width:556px){
    width:98%;
    padding:0px;
    margin:10px 0;
    h2 { 
      font-size:1.1rem !important;
    }
  }
  
  
`
