import styled from '@emotion/styled'

export default styled.ul`
text-align: left;
line-height: 1.5;
font-size:1rem;
margin:0px;
margin-top:8px;
padding:4px;
list-style:none;
font-weight:300;
li, li a {
  padding:0;
  margin:0;
  font-size:1.1rem;
}
@media(max-width:500px){

  li a {
    font-size:1rem;
    padding:0;
    margin:0;

  }

}
`
