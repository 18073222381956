const colors = {
  black: '#000',
  white: '#fff',
  gray: '#333',
  grey: '#4682B4',
  red: '#ff0033',
  blue: '#3333FF',
  green: '#5858fa',
  lila: '#8062ff',
  pink: '#ff0080',
  blau: '#0080ff',
  mygreen: '#01DF01',
  mypink: '#FA56AC',
  obscura: '#5858fa',
  welten: '#E74C3C',
  insta: 'teal',
  sattler: '#d5102f',
  alltag: '#5958fb',
  yellow: '#fffa22',
  kreiensiek: '#5858fa',
  lepidoptera: '#acd8f5',
  verluste: '#3352a2',
  antonio: '#4a96d2',
  knevels: '#ea5046',
  mora: '#2294ac'
}

export default colors
