const fontsizes= {
    h2small: '1rem',
    h2great: '1.4rem',
    h2middle: '1rem',
    h3small: '.85rem',
    h3great: '1rem',
     
    h4small: '.8rem',
    h4great:'.95rem',
    small: '.9rem',
    great:'1.6rem',
    middle:'1.4rem',
    xgreat:'1.95rem',
    xmiddle:'1.75rem'

    }

export default fontsizes
