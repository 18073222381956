import styled from '@emotion/styled'

export default styled.section`
  display:flex;
  flex-wrap: wrap;
  width:100%;
  justify-content:space-between;
  
  div {
   
    flex-basis:20%;
    margin:2%;
  }
  @media(max-width: 529px){
    div {
     flex-basis:80%;
     margin: 37px 10%;
    }
   }
  @media(min-width:530px) and (max-width: 768px){
   div {
    flex-basis:36%;
    
   }
  }
  @media(max-width: 375px){
    div {
     flex-basis:80%;
     margin: 40px 0;
    }
   }
  `
