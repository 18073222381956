import styled from '@emotion/styled'

export default styled.p`
  text-align: left;
  font-weight:300;
  font-size: 1.1rem;
  padding:0;
  margin:0;
  strong {
    font-weight:500;
  }
  @media(max-width:768px){
    font-size: 1rem;
  }
  
  
`
