import styled from '@emotion/styled'

export default styled.h1`
  font-size: 1.7rem;
  font-weight: 500;
  line-height: 1.3;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 30px;
  padding-bottom: 10px;
  letter-spacing:.04rem;
  font-family: 'Roboto Slab';
  @media(max-width:800px){
    font-size:1.4rem;
  }
  @media(min-width:801px){
    font-size:1.5rem;
    line-height: 1.7;
    font-weight: 500;
  }
`
