module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/blog your pathPrefix should be "blog"
  siteTitle: 'KULTURRAUM Markt1', // Navigation and Site Title
  siteTitleAlt: 'Markt 1', // Alternative Site title for SEO
  siteTitleShort: 'Markt1 ', // short_name for manifest
  siteUrl: 'https://www.markt1-bacharach.de/', // Domain of your site. No trailing slash!
  siteLanguage: 'de', // Language Tag on <html> element
  siteLogo: 'images/logo.png', // Used for SEO and manifest, path to your image you placed in the 'static' folder
  siteDescription: 'Kunst, Literatur,Galerie Markt1, Bacharach, Galerie Bacharach, Austellungen,Galerie Markt 1, Lesungen, Kultur-Events. Das ist das Profil von Markt 1 in Bacharach',
  author: 'Rene Broich, Katrin Gloggengießer', // Author for schemaORGJSONLD
  organization: 'Markt 1',

  // siteFBAppID: '123456789', // Facebook App ID - Optional

  userTwitter: '@Markt1B', // Twitter Username
  ogSiteName: 'markt1-bacharach', // Facebook Site Name
  ogLanguage: 'de_DE',
  googleAnalyticsID: '',

  // Manifest and Progress color
  themeColor: '#5348FF',
  backgroundColor: '#333',

  // Social component
  //twitter: 'https://twitter.com/markt1b',
  //twitterHandle: '@Markt1B',

  facebook: 'https://www.facebook.com/markt.bacharach.98',
  facebookHandle: 'markt.bacharach',

  instagram: 'https://instagram.com/markt1kulturraum',
  instgramHandle: 'markt1kulturraum',
  

  
}
