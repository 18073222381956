import styled from '@emotion/styled'

export default styled.a`
  text-align: left;
  line-height: 1.5;
  font-size:1.1rem;
  margin:0;
  padding:0;
  
  font-weight:500;
  text-decoration:underline;
  

  @media(max-width:500px){
    font-size:1rem;
    padding:0;
    margin:0;
    text-align:left;
  }
`
