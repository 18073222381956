import Typography from 'typography'
import '../fonts/fonts.css'

export const fonts = {
  regular: 'Roboto',
  regularItalic: 'Roboto Slab',
  semibold: 'Roboto',
  semiboldItalic: 'Roboto Slab',
  bold: 'Roboto Slab'
}

const typography = new Typography({
  baseFontSize: '18px',
  baseLineHeight: 1.55,
  headerLineHeight: 1.4,
  headerFontFamily: [fonts.bold, 'serif'],
  bodyFontFamily: [fonts.regular, 'sans-serif'],
  headerColor: 'hsla(0,0%,0%,0.9)',
  bodyColor: 'hsla(0,0%,0%,0.8)',

  overrideStyles: ({ rhythm }) => ({
    'a:focus': {
      outline:'none',
    },
    'button:focus': {
      outline:'none',
    },
    h1: {
      color: 'hsla(0,0%,0%,0.75)',
      fontSize: '2.5rem',
      fontWeight: 500,
      margin:'0 !important',
      padding:'0 !important',
    },
    h2: {
      color: 'hsla(0,0%,0%,0.775)',
     
      textAlign:'center',
      fontWeight:'300 !important',
      
    },
    h3: {
      color: 'hsla(0,0%,0%,0.8)',
      fontSize: '.9rem'
    },
    
   '@media(max-width:796px)':{
     
       h1:{
         fontSize: '1.7rem !important',
    
         
       },
       h2:{
        fontSize:'1.49rem !important',
        fontWeight:100,
        
        
      },
      h3:{
        fontSize:'1rem'
      }
    }
    

  }),
})
// Hot reload typography in development.
if (process.env.NODE_ENV !== 'production') {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
