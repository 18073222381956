import React, { useState } from 'react'
import { css } from '@emotion/core'
import Container from '../Container'

//import { bpMaxSM } from '../../lib/breakpoints'

import { useTheme } from '../Theming'

const Toggle = ({ children }) => {
  const [isToggledOn, setToggle] = useState(false)
  const toggle = () => {
    setToggle(!isToggledOn)
    if(typeof window !=='undefined')
    window.scroll(0,0)
  }
  const theme = useTheme()
  let color = theme.colors.black

  if(typeof window !=='undefined'){
    switch(window.location.pathname){
      case '/':
      color = theme.colors.red
      break
      case '/moga-zirden':
        color = theme.colors.mora
        break
        case '/mogazirden':
          color = theme.colors.mora
          break
      case '/isabel-antonio':
        color = theme.colors.antonio
        break
        case '/antonio-isabel':
          color = theme.colors.antonio
          break
      case '/verluste':
        color = theme.colors.verluste
        break
      case '/evelyn-sattler':
      color = theme.colors.sattler
      break
      case '/sattler':
          color = theme.colors.sattler
      break
      case '/ausstellung':
      color = theme.colors.mypink
      break
      case '/bacchanale':
      color = theme.colors.mypink
      break
     case '/urbansketcher':
      color = theme.colors.lila
      break
      case '/buga2029':
      color = theme.colors.mygreen
      break
      case '/jalltag':
      color = theme.colors.alltag
      break
      case '/juedischer-alltag':
      color = theme.colors.alltag
      break
      case '/sketcher':
      color = theme.colors.lila
      break
      case '/weltenfrauen':
      color = theme.colors.welten
      break
      case '/fotos-weltenfrauen':
          color = theme.colors.welten
      break
      case '/kulturtipps':
      color = theme.colors.grey
      break
      case '/filme':
        color=theme.colors.pink
        break
      case '/markt1filme':
        color=theme.colors.pink
        break
      case '/cameraobscura':
      color = theme.colors.yellow
      break
      case '/camera_obscura':
      color = theme.colors.yellow
      break
      case '/minimalbehausung':
      color = theme.colors.mygreen
      break
      case '/kontakt':
       color = theme.colors.blue
      break
      case '/datenschutz':
        color = theme.colors.blue
      break
      case '/foerderer':
        color = theme.colors.blue
      break
      case '/jkreiensiek':
       color = 'green'
      break
      case '/heinrichlee':
        color = 'green'
      break
      case '/lepidoptera':
        color = theme.colors.lepidoptera
      break
      case '/benjamin-may':
        color = theme.colors.lepidoptera
      break
      default:
        color = theme.colors.red

    }
  }

  return (
    <div css={css`
       padding-right:30px;
       margin-right:20px;
       height:70px;
       margin-top:-130px;
       ::webkit-scrollbar {
       
         background:transparent;
       }  
       @media(max-width:500px){
        margin-top:-110px;
        margin-right:10px;
       }
    `}>

      <button
        onClick={toggle}
        aria-label={`${isToggledOn ? 'close menu' : 'open menu'}`}
        css={css`
          z-index: 30;
         
          position: relative;
          background: transparent;
          border: 2px;
          :hover:not(.touch),
          :hover {
            cursor:pointer;
          }
          :focus {
            background: transparent;
            border: none;
            outline: none;
          }
        `}
      >
        <div
          css={css`
            width: 34px;
            height: 4px;
            background: ${color};
            position: fixed;
            display:flex;
           

            ${isToggledOn ? 'background: transparent' : `background: ${color}`};
            transition: all 250ms cubic-bezier(0.86, 0, 0.07, 1);
            ::before {
              content: '';
              top: -8px;
              width: 34px;
              height: 4px;

              background: ${color};
              position: absolute;
              left: 0;
              ${isToggledOn
                ? 'transform: rotate(45deg); top: 0; '
                : 'transform: rotate(0)'};
              transition: all 250ms cubic-bezier(0.86, 0, 0.07, 1);
            }
            ::after {
              top: 8px;
              content: '';
              width: 34px;
              height: 4px;
              background: ${color};
              position: absolute;
              left: 0;
              ${isToggledOn
                ? 'transform: rotate(-45deg); top: 0;'
                : 'transform: rotate(0)'};
              transition: all 250ms cubic-bezier(0.86, 0, 0.07, 1);
            }
          `}
        />
      </button>
      {isToggledOn && (
        <div
          css={css`
          ::webkit-scrollbar {
            height:0;
             background:transparent;
           }
            position: absolute;
            z-index: 20;
            left: 0;
            top: 0;
            padding:20px;
            width: 100vw;
            height: 100vh;
            display: flex;
            align-items: center;
            background: ${theme.colors.headerBg};
            
          `}
        >
          <Container
            css={css`
            
             padding:40px 0;
              bottom:0;
              flex-direction: column;
              align-items: center;
            
              justify-content: space-evenly;
              a {
                color: white;
                font-size: 1.6rem;
                margin: 10px 0;
                padding: 10px;
                border-radius: 5px;
                :hover {
                  background: rgba(0, 0, 0, 0.2);
                }
                
              }
              .active {
                opacity:  0.4;
              }
              @media(max-width:450px){
               position:absolute;
               top:0;
               left:0;
               right:0; 
               a {
                 font-size:1.3rem;
               }
              }
              @media(min-width:1000px){
                position:absolute;
                top:0;
                left:0;
                right:0; 
                a {
                  font-size:2.2rem;
                }
               }
             `}
              >
             
              
           
          
            {children}
          </Container>
        </div>
      )}
    </div>
  )
}

export default Toggle
