import styled from '@emotion/styled'

export default styled.blockquote`
border:none;
position:relative;
left:-18px;
padding:10px 0 0 0;
font-family: Roboto;
font-style:normal;
border:none;
width: 100%;


p {
  padding:15px 0;
  font-size: 3rem;
  text-align:0;
  margin-left:0;
  display:inline;
  line-height:1.1!important;
}
@media(max-width:490px){

  p {
    font-size:1.4rem;
  }
}


    


}`
