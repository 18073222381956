import React, { Fragment, useState, useEffect } from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { MDXProvider } from '@mdx-js/react'
import { Global, css,keyframes } from '@emotion/core'
import { ThemeProvider, themes } from './Theming'
import { bpMaxSM } from '../lib/breakpoints'
import mdxComponents from './mdx'
import Header from './Header'
import reset from '../lib/reset'
import { fonts } from '../lib/typography'
import config from '../../config/website'
import Footer from '../components/Footer'
import Container from './Container'
import Link from './Link'


//import {FaClose} from 'react-icons/fa'

const bounce = keyframes`
  from, 20%, 53%, 80%, to {
    transform: translate3d(0,0,0);
  }

  40%, 43% {
    transform: translate3d(0, -30px, 0);
  }

  70% {
    transform: translate3d(0, -15px, 0);
  }

  90% {
    transform: translate3d(0,-4px,0);
  }
`


const getGlobalStyles = theme => {
  return css`
  .mybounce {
    display:flex;
    justify-content:center;
    font-size:1.3rem;
   
    animation: ${bounce} 1s ease infinite;
    width:100%;
  }
 
  
  html {
    overflow:scroll;
     overflow-x:hidden;
   }

   ::webkit-scrollbar {
     height:0;
     background:transparent;
   }
    body {
      background: ${theme.colors.bodyBg};
      color: ${theme.colors.text};
      ::webkit-scrollbar {
        height:0;
         background:transparent;
       }
       font-weight:300;
    }
    &::selection {
      color: ${theme.colors.white};
      background-color: ${theme.colors.primary};
    }

    a {
      color: ${theme.colors.text};
      text-decoration: none;
      &:hover,
      &:focus {
        color: ${theme.colors.green};
      }
      font-size:1rem;
    }

    p {
      font-size:1rem;
      color:${theme.colors.text};
    }
  
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: ${theme.colors.text};
      font-family: 'Roboto Slab';
      padding:0;
      margin:30px 0 10px 0;
      font-weight:500;
      a {
        color: ${theme.colors.text};
        &:hover,
        &:focus {
          color: ${theme.colors.green};
        }
      }
      
    }
   
   

    hr {
      margin: 50px 0;
      border: none;
      border-top: 1px solid ${theme.colors.gray};
      background: none;
    }
    
    input {
      border-radius: 4px;
      border: 1px solid ${theme.colors.gray};
      padding: 5px 10px;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
      font-family: ${fonts.regular};
      margin-top: 5px;
      ::placeholder {
        opacity: 0.4;
      }
    }

    .gatsby-resp-image-image {
      background: none !important;
      box-shadow: 0;
      object-fit:cover;
     
    }
   
    button {
      outline:none;
      border: none;
      color: ${theme.colors.text};
      padding: 0px;
      cursor: pointer;
      
      transition: all 150ms;
      :hover,:selected,:visited {
        background: #00ff33;
        outline:none;
        border:none
      }
    }
    pre {
      background-color: #061526 !important;
      border-radius: 4px;
      font-size: 16px;
      padding: 10px;
      overflow-x: auto;
    }
    

   
   


  `
}

export default ({
  site,
  frontmatter = {},
  children,
  noFooter,
  noSubscribeForm,
}) => {
  const initializeTheme = () => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem('theme') || 'default'
    } else {
      return 'default'
    }
  }

  const [themeName, setTheme] = useState(initializeTheme)
  const [shinweis, setHinweis] = useState(true)
  const [xtermin,setTermin] = useState(true)

 const Meldung = ()=>(
      <Container>
        <div css={css`
          padding:0px 10px;
          h1 {
            font-size:1.2rem;
          }
          p{
            font-size:.9rem;
          }
          max-width:80%;
          @media(max-width:500px){
            max-width:90%;
            h1 {
              font-size:1rem;
            }
            p{
              font-size:.7rem;
            }
            button {
              font-size:.9rem;
              text-align:left;
              margin-left:0;
              padding-left:0:
            }
          }
        `}>
        <h1 >Hinweis zur Datenerhebung</h1>
        <p>Zur Optimierung unserer Webseite nutzen wir Tools von Google, Social-Media-Dienste wie Twitter, Facebook und Instagram,
        die Video-Plattform Vimeo.
        <br/> 
        In unseren Datenschutzbestimmungen finden Sie Links, mit denen Sie den Zugriff dieser Anbieter auf Ihre Daten beschränken können.<br/>
       <br/> Wir selbst ermitteln keine persönlichen Daten und analysieren nur statistische Daten. <br/>
        <br/>
        <Link to="/datenschutz"> Datenschutzbestimmungen  &rarr;</Link>

        </p>
        
          <button  onClick={()=>mach()}><span style={{padding:5,color:`${theme.colors.red}`}}>Ich akzeptiere Ihre Datenschutzbestimmungen.</span></button>
          </div>
      </Container>

 )



const VHinweis = ()=>(
  <Container>
    <div style={{padding:20,background:'#55ffff',margin:10}}>
    <h1 style={{fontSize:'1.4rem'}}>Open Air Eröffnung Markt 1</h1>
    <p><span style={{fontWeight:500}}>16.05. // 16:00 Uhr  </span>
     &nbsp;Kontrolliertes Betreten der Galerie nach gesetzlichen Sicherheitsauflagen.
    
     </p>
     <br/>
    <button style={{cursor:'pointer',border:0,background:'white'}} onClick={()=>machtermin()}>x</button>
    </div>
  </Container>

)
 
  useEffect(() => {
    if(typeof window ==='undefined') return
    localStorage.setItem('theme', themeName)
  }, [themeName])

  function mach(){
   setHinweis(true)
   if(typeof window==='undefined') return
   localStorage.setItem('shinweis',true)
  }
  function machtermin(){
    setTermin(true)
    if(typeof window==='undefined') return
    localStorage.setItem('xtermin',true)
   }

  useEffect(()=>{
    if(typeof window!=='undefined') {
      let g= localStorage.getItem('shinweis')
      if(g===null || g===false){
        setHinweis(false)
      }
      else {
        setHinweis(true)
      }
    }
  },[])

  useEffect(()=>{
    if(typeof window!=='undefined') {
      let g= localStorage.getItem('xtermin')
      if(g===null || g===false){
        setTermin(true)
      }
      else {
        setTermin(true)
      }
    }
  },[])
  const toggleTheme = name => setTheme(name)
  const theme = {
    ...themes[themeName],
    toggleTheme: toggleTheme,
  }
  const {
    description: siteDescription,
    keywords: siteKeywords,
  } = site.siteMetadata

  const {
    keywords: frontmatterKeywords,
    description: frontmatterDescription,
  } = frontmatter

  const keywords = (frontmatterKeywords || siteKeywords).join(', ')
  const description = frontmatterDescription || siteDescription
 let mycolor=theme.colors.green;
  if(typeof window!=='undefined'){
    switch(window.location.pathname){
      case '/':
        mycolor=theme.colors.red
        break
        case '/moga-zirden': 
        mycolor=theme.colors.mora
        break
        case '/mogazirden': 
        mycolor=theme.colors.mora
        break
        case '/antonio-isabel': 
        mycolor=theme.colors.antonio
        break
        case '/isabel-antonio': 
        mycolor=theme.colors.antonio
        break
        case '/verluste': 
          mycolor=theme.colors.verluste
          break
        case '/evelyn-sattler':
          mycolor=theme.colors.sattler
          break
          case '/sattler':
            mycolor=theme.colors.sattler
            break
      case '/filme':
        mycolor=theme.colors.pink
        break
       
        case '/markt1filme':
          mycolor=theme.colors.pink
          break
      case '/ausstellung':
          mycolor=theme.colors.mypink
          break
      case '/bacchanale':
            mycolor=theme.colors.mypink
            break
      case '/weltenfrauen':
            mycolor=theme.colors.welten
              break
      case '/fotos-weltenfrauen':
            mycolor=theme.colors.welten
             break
      case '/urbansketcher':
          mycolor=theme.colors.lila
          break
      case '/sketcher': 
            mycolor=theme.colors.lila
            break
      case '/buga2029': 
            mycolor=theme.colors.mygreen
            break
      case '/minimalbehausung': 
            mycolor=theme.colors.mygreen
            break
      case '/kulturtipps': 
            mycolor=theme.colors.grey
            break 
      case '/cameraobscura': 
            mycolor=theme.colors.yellow
            break 
      case '/camera_obscura': 
            mycolor=theme.colors.yellow
            break
      case '/kontakt': 
            mycolor=theme.colors.blue
            break 
      case '/datenschutz': 
            mycolor=theme.colors.blue
            break 
      case '/foerderer': 
            mycolor=theme.colors.blue
            break 
      case '/jalltag': 
            mycolor=theme.colors.alltag
            break
      case '/juedischer-alltag': 
            mycolor=theme.colors.alltag
            break
      case '/jkreiensiek': 
            mycolor='green'
            break 
      case '/heinrichlee': 
            mycolor='green'
            break 
      case '/lepidoptera': 
            mycolor=theme.colors.lepidoptera
            break 
      case '/benjamin-may':
            mycolor=theme.colors.lepidoptera
            break     
      
       
      default:
      mycolor=theme.colors.red
      break 
    }
  }
  return (
    <ThemeProvider theme={theme}>
      
      <Fragment>
        <Global styles={reset()} />
        <Global styles={getGlobalStyles(theme)} />
        <Container 
          css={css`
            display: flex;
            flex-direction: column;
            margin:auto;
            width:100%; 
            padding:0 1%;
           
            margin:auto;
            min-height: 100vh;
            max-width:1200px;
            border-left: 4px solid ${mycolor} ;
            border-right: 4px solid ${mycolor};
            
            // @media(max-width:376px){
            //   img {
            //     width:100%;
            //   }
            //   width:100%; 
            //   padding:0;

            //    margin:auto;
            //    border-left: 4px solid ${theme.themeName==='dark'? theme.colors.gray:mycolor};
            //    border-right:4px solid ${theme.themeName==='dark'? theme.colors.gray:mycolor};
               
            //  }
             @media(min-with: 377px) {
             
              margin:auto;
              border-left: 2px solid ${theme.themeName==='dark'? theme.colors.gray:mycolor};
              border-right 2px solid ${theme.themeName==='dark'? theme.colors.gray:mycolor};
              
            }
            @media(min-width:800px){
             
              margin:auto;
              border-left: 30px solid ${theme.themeName==='dark'? theme.colors.gray:mycolor};
              border-right 30px solid ${theme.themeName==='dark'? theme.colors.gray:mycolor};
              
            }
            

          `}
        >
          <Helmet
            title={config.siteTitle}
            meta={[
              { name: 'description', content: description },
              { name: 'keywords', content: keywords },
            ]}
          >
            <html lang="de" />
            <noscript>This site runs best with JavaScript enabled.</noscript>
          </Helmet>
          <Header />

          { !shinweis?
          <Meldung />
         : null
        }
        { !xtermin?
          <VHinweis/>
         : null
        }


          <MDXProvider components={mdxComponents} role="main">
            <Fragment>{children}</Fragment>
          </MDXProvider>
          <Container css={css`
          display:flex;
         margin:auto;
         width:100%;
         justify-content:space-between;
         padding-top:50px;
         a {
           color: ${theme.colors.text};
         }
          ${bpMaxSM}{
            flex-direction:column;
            a{
              text-align:center;
            }
          }
        `}>

        {!noFooter && (
          <Footer 
            author={site.siteMetadata.author.name}
            noSubscribeForm={noSubscribeForm}
          />
        )}
          <Link role="navigation" style={{fontSize:"0.9rem",fontWeight:300, letterSpacing: "0.05rem"}} to="/datenschutz" activeClassName="active" aria-label="Datenschutzerklärung">
          Datenschutz
      </Link>
      <Link role="navigation" style={{fontSize:"0.9rem",fontWeight:300, letterSpacing: "0.05rem"}} to="/kontakt" activeClassName="active" aria-label="Kontakt">
        Kontakt
      </Link>
      <Link role="navigation" style={{fontSize:"0.9rem", fontWeight:300, letterSpacing: "0.05rem", marginBottom:50}} to="/impressum" activeClassName="active" aria-label="Impressum">
        Impressum
      </Link>
      </Container>

        </Container>
      </Fragment>


    </ThemeProvider>
  )
}

export const pageQuery = graphql`
  fragment site on Site {
    siteMetadata {
      title
      description
      author {
        name
      }
      keywords
    }
  }
`
