import React from 'react'
import { css} from '@emotion/core'
import { bpMaxSM } from '../lib/breakpoints'


import Container from './Container'
import { Facebook,Instagram} from './Social'


const Footer = () => (
  <footer>
    <Container
      css={css`
        padding-top: 0;

        width:90%;
        ${bpMaxSM} {
          padding-top: 0;
        }
      `}
    >

      <div
        css={css`
          display: flex;
         
          justify-content: center;
        `}
      >



<Facebook />&nbsp;&nbsp;&nbsp;
<Instagram />&nbsp;&nbsp;&nbsp;
<div style={{position:'relative',top:'-4px',marginLeft:10,aspectRatio: 'auto 40 / 40',height: 40}}>
<a href="https://kulturnetz-oberes-mittelrheintal.org" rel="noreferrer noopener">
  <img src="/images/icon2.png" width="40" height="40" alt="Icon von K.O.M"/>
</a>
</div>

   
         
         
        </div>

    </Container>
  </footer>
)

export default Footer
