import styled from '@emotion/styled'

export default styled.h2`
text-align: left;
font-size: 2rem;
margin: 3rem 0 0 0;
font-weight: 500;
font-family: Roboto;
font-style: normal;
padding:0;
line-height:1.3;

@media(max-width:768px){
  margin-top: 1.1rem;
  margin-bottom: 0.5rem;
  font-size:1.8rem;
  line-height:1.25;
  font-weight: 500;
  
}

@media(min-width:769px){
  margin: 2rem 0 .8rem 0;
  font-size:1.8rem;
  
  
}

`
