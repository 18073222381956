import styled from '@emotion/styled'

export default styled.h3`
text-align: left;
font-size: 1.25rem;
margin: 3rem 0;
font-weight: 300;
font-family: Roboto;
font-style: normal;
padding:0;
margin:0;
line-height:1.6;




@media(max-width:500px){
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  margin:0;
line-height:1.25;
  
}

@media(min-width:501px){
  margin-top: 2rem;
  
  
  
}

`
