import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import { css } from '@emotion/core'
import { useTheme } from '../Theming'

import MobileMenu from './MobileMenu'
import Links from './Links'
//import Logo from './logo.png'
//import Logow from './logo_weiss.png'
import Logo from './LOGO_g.gif'
//import Logogw from './logo_weiss.png'
import Logow from './LOGO_gw.gif'
import Container from '../Container'


const Header = ({ site,siteTitle }) => {
  const theme = useTheme()

  return (
    <Container>
    <div>
        <nav
          css={css`
          ::webkit-scrollbar {
            height:0;
             background:transparent;
           }
          display: flex;
          flex-direction:column;
          margin:0 auto 0 auto;
          
          flex-wrap: wrap;
          height:100%;
          justify-content:space-around;
           
            img.logo {
             
              width:100px;
              
            }
            @media(max-width:779px){
             
             img.logo {
              
                width:100px;
                margin-left: 10px;
              
              }
            }
            @media(min-width:778px){
           
              img.logo {
                padding-left:0;
                width:150px;
                
              }
            }
            
          `}
        >
          
         {  <Link
            to="/"
            aria-label="go to homepage"
            role="navigation"
           
          >
            {theme.themeName==="default" || theme.themeName!=="dark" ?
            <img className="logo"  src={Logo}  alt="Logo" />
             :
             <img className="logo" src={Logow}  alt="Logo" />

             }

          </Link> }
         


         <div css={css`
          
           display:flex;
           
           padding:0;
           margin:0;
           justify-content:flex-end;
          width:100%;
         
         `}>
          
                  <MobileMenu>
                    <Links />
                </MobileMenu>
              
          
          
           
       


         </div>
         </nav>
         
      </div>
      </Container>
  )
}

const ConnectedHeader = props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <Header siteTitle={data.site.siteMetadata.title} {...props} />
    )}
  />
)

export default ConnectedHeader
